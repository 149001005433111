<template>
  <b-card-code title="Detail Siswa">
    <b-row class="match-height">
      <b-col
        cols="3"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="require('@/assets/images/avatars/2.png')"
            text="Ardan Ajitra"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                <!-- {{ userData.fullName }} -->
                {{ studentDatas.name }}
              </h4>
              <span class="card-text"> {{ studentDatas.nisn }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <!-- <b-button variant="primary"> Edit </b-button>
              <b-button variant="outline-danger" class="ml-1">
                Delete
              </b-button> -->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">23.3k</h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">$99.87k</h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div> -->
      </b-col>
      <b-col
        cols="3"
        class="border-left"
      >
        <h5 class="mb-0">
          Kelas
        </h5>
        <div class="mb-2 text-secondary">
          {{ studentClass }}
        </div>
        <h5 class="mb-0">
          Semester/TA
        </h5>
        <div class="mb-2 text-secondary">
          <!-- {{ studentClass }} -->
        </div>
        <h5 class="mb-0">
          Jurusan
        </h5>
        <div class="mb-2 text-secondary">
          <!-- {{ studentClass }} -->
        </div>
        <h5 class="mb-0">
          Tlp/Handphone
        </h5>
        <div class="mb-2 text-secondary">
          <!-- {{ studentClass }} -->
        </div>
      </b-col>
      <b-col
        cols="3"
        class="border-left"
      >
        <h5 class="mb-0">
          Tempat & Tgl Lahir
        </h5>
        <div class="mb-2 text-secondary">
          {{ studentBirth }}
        </div>
        <h5 class="mb-0">
          Jenis Kelamin
        </h5>
        <div class="mb-2 text-secondary">
          <!-- {{ studentClass }} -->
        </div>
        <h5 class="mb-0">
          Agama
        </h5>
        <div class="mb-2 text-secondary">
          <!-- {{ studentClass }} -->
        </div>
        <h5 class="mb-0">
          Alamat
        </h5>
        <div class="mb-2 text-secondary">
          {{ studentAddress }}
        </div>
      </b-col>
      <b-col
        cols="3"
        class="border-left"
      >
        <h5 class="mb-0">
          Jarak Ke Sekolah
        </h5>
        <div class="mb-2 text-secondary">
          <!-- {{ studentClass }} -->
        </div>
        <h5 class="mb-0">
          Anak ke/dari
        </h5>
        <div class="mb-2 text-secondary">
          <!-- {{ studentClass }} -->
        </div>
        <h5 class="mb-0">
          Golongan darah
        </h5>
        <div class="mb-2 text-secondary">
          <!-- {{ studentClass }} -->
        </div>
      </b-col>
    </b-row>

    <template #code>
      {{ codeIcon }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BAvatar,
} from 'bootstrap-vue'
// import { codeIcon } from './code'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'DataSiswaEdit',
  components: {
    BCardCode,
    BRow,
    BCol,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true,
    // },
  },
  data: () => ({
    studentDatas: {},
  }),
  computed: {
    studentClass() {
      return this.studentDatas?.academic_classes?.data?.master_class?.data
        ?.class_desc
    },
    studentBirth() {
      return `${this.studentDatas?.birth_place}, ${this.studentDatas?.birth_date}`
    },
    studentAddress() {
      return this.studentDatas?.address
    },
  },
  created() {
    this.detailStudent()
  },
  methods: {
    async detailStudent() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/students/${studentId}`)
        this.studentDatas = response.data.data
      } catch (err) {
        console.log(err)
      }
    },
  },
  setup() {
    // const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      // resolveUserRoleVariant,
    }
  },
}
</script>
